import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-bottom"
  }, [_c('div', {
    staticClass: "v2-g-w1200"
  }, [_vm._m(0), _c('div', {
    staticClass: "v2-g-flex-row v2-bottom2 v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-g-flex-row v2-g-flex-space-between",
    staticStyle: {
      "width": "500px",
      "height": "100px"
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "v2-g-flex-column v2-g-flex-space-between"
  }, [_vm._m(2), _c('div', {
    staticClass: "v2-sub-title"
  }, [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'v2-login'
        });
      }
    }
  }, [_vm._v("免费注册")])]), _vm._m(3)])]), _vm._m(4)]), _c('div', {
    staticClass: "divide"
  }), _vm._m(5), _c('div', {
    staticClass: "flex-center",
    staticStyle: {
      "margin": "11px 0"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("公司地址：重庆市南岸区玉马路8号B栋")]), _c('div', {
    staticStyle: {
      "display": "inline-block",
      "position": "relative"
    }
  }, [_c('embed', {
    attrs: {
      "src": "https://program.xinchacha.com/web/1427192787672256512=*.pujian.com.svg",
      "width": "96",
      "height": "30",
      "type": "image/svg+xml",
      "pluginspage": "//www.adobe.com/svg/viewer/install/"
    }
  }), _c('div', {
    staticStyle: {
      "position": "absolute",
      "left": "0",
      "top": "0",
      "width": "100%",
      "height": "100%",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openNetworkSecurity
    }
  })])]), _c('div', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm._v("Copyright ©2023 www.pujian.com All rights reserved.")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-g-flex-row v2-bottom1 v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-zpbz v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-g-flex-column"
  }, [_c('div', {
    staticClass: "v2-big"
  }, [_vm._v("正品保证")]), _c('div', {
    staticClass: "v2-small"
  }, [_vm._v("国家认证，正规合法，值得信赖！")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/sy_zhengpin.png")
    }
  })])]), _c('div', {
    staticClass: "v2-ppsq v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-g-flex-column"
  }, [_c('div', {
    staticClass: "v2-big"
  }, [_vm._v("品牌授权")]), _c('div', {
    staticClass: "v2-small"
  }, [_vm._v("秉承行业高标准，确保正品可信！")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/sy_shouquan.png")
    }
  })])]), _c('div', {
    staticClass: "v2-ysbz v2-g-flex-row v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-g-flex-column"
  }, [_c('div', {
    staticClass: "v2-big"
  }, [_vm._v("隐私包装")]), _c('div', {
    staticClass: "v2-small"
  }, [_vm._v("保密包装，充分保护个人隐私！")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/sy_yinsi.png")
    }
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-g-flex-column v2-g-flex-space-between"
  }, [_c('div', {
    staticClass: "v2-title"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "/about-us.html"
    }
  }, [_vm._v("关于我们")])]), _c('div', {
    staticClass: "v2-sub-title"
  }, [_c('a', {
    attrs: {
      "href": "http://qy.pujian.com/#/about",
      "target": "_blank"
    }
  }, [_vm._v("企业简介")])]), _c('div', {
    staticClass: "v2-sub-title"
  }, [_c('a', {
    attrs: {
      "href": "http://qy.pujian.com/#/about",
      "target": "_blank"
    }
  }, [_vm._v("发展历程")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-title"
  }, [_c('a', [_vm._v("新手指南")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-sub-title"
  }, [_c('a', {
    attrs: {
      "href": "/register-report.html",
      "target": "_blank"
    }
  }, [_vm._v("注册协议")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-g-flex-row v2-g-flex-space-between qr"
  }, [_c('div', [_c('img', {
    staticStyle: {
      "height": "100px"
    },
    attrs: {
      "src": "/pjyy-deepexi-aggregation/api/v1/qrcode/getQrCode?url=https://www.pujian.com/pjyy-deepexi-aggregation/api/v1/qrcode/rouse?appId=__UNI__1DDC2C0"
    }
  }), _c('div', {
    staticClass: "tip"
  }, [_vm._v("下载普健医药APP")])]), _c('div', [_c('img', {
    staticStyle: {
      "height": "100px"
    },
    attrs: {
      "src": require("@/assets-v2/images/xcx.png")
    }
  }), _c('div', {
    staticClass: "tip"
  }, [_vm._v("扫码进入微信小程序")])]), _c('div', [_c('img', {
    staticStyle: {
      "height": "100px"
    },
    attrs: {
      "src": require("@/assets-v2/images/gzh.73939815.jpg")
    }
  }), _c('div', {
    staticClass: "tip"
  }, [_vm._v("扫码进入公众号")])]), _c('div', [_c('img', {
    staticStyle: {
      "height": "100px"
    },
    attrs: {
      "src": require("@/assets-v2/images/dy.png")
    }
  }), _c('div', {
    staticClass: "tip"
  }, [_vm._v("扫码进入抖音号")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "v2-g-flex-row v2-bottom3 v2-g-flex-space-between"
  }, [_c('div', [_vm._v(" 互联网药品信息服务资格证书:(渝)-经营性-2018-0002 ")]), _c('div', {
    staticClass: "v2-g-flex-column v2-g-flex-space-between"
  }, [_c('div', [_c('a', {
    staticStyle: {
      "color": "#3875F6"
    },
    attrs: {
      "href": "https://beian.miit.gov.cn/#/Integrated/index",
      "target": "_blank"
    }
  }, [_vm._v("渝ICP备18008873号-3")]), _vm._v("; 监管机构： 国家食品药品监管局 重庆市食品药品监管局51La")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/ga.png")
    }
  }), _c('a', {
    staticStyle: {
      "color": "#3875F6"
    },
    attrs: {
      "href": "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802004051",
      "target": "_blank"
    }
  }, [_vm._v("渝公网安备 50010802004051号")])])]);
}];
export { render, staticRenderFns };