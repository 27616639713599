<template>
    <div class="v2-top">
        <div class="height41">
            <div class="v2-g-w1200 v2-g-flex-row  v2-g-flex-space-between">
                <div class="top-left">
                    <span class="welcome">欢迎来普健商城！</span>
                    <div class="loged" style="display: flex; align-items: center;">
                        <span style="display: flex; align-items: center;">
                          <img src="@/assets-v2/images/sy_hai.png" />
                          <span class="v2-customer" v-if="isExternal">[{{ userName }}]</span>
                          <span v-else style="color: #1B45F3; line-height: 24px; display: flex; align-items: center;">
                            <span class="v2-customer">[{{ loginCompanyName }}]</span>
                            <span>【切换】</span>
                          </span>
                        </span>
                        <span class="to-login" @click="toLogOut">退出</span>
<!--                        <span class="to-login" style="margin-left:10px;" @click="$router.push({name:'home'})">返回旧版</span>-->
                        <ul class="user-list" v-if="!isExternal">
                            <li class="cus-search">
                                <div><input type="text" v-model="searchName" @input="quickSearch"
                                        placeholder="输入客户名称查询" /></div>
                            </li>
                            <template v-if="(copyUserList.length == 0)">
                                <li class="nodata">

                                    <img src="@/assets-v2/images/nodata.png" />
                                    <span>暂无相关商品</span>

                                </li>
                            </template>
                            <li v-for="(item, index) in copyUserList" :key="index"
                                :class="item.name === userName ? 'selected' : ''" :title="item.name"
                                @click="item.name === userName ? undefined : onCheckUser(item)">
                                {{ item.name }}
                            </li>
                            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
                        </ul>
                    </div>

                </div>
                <div class="v2-g-flex-row v2-g-flex-space-between">
                    <div class="v2-link1">
                        <a href="javascript:;" @click="jump('v2-electronicInvoiceList')">发票下载</a>
                        <i></i>
                        <a href="javascript:;" @click="jump('v2-download')">资料下载</a>
                        <i></i>
                        <a href="javascript:;" @click="jump('v2-information')">个人中心</a>
                        <i></i>
                    </div>
                    <div class="v2-link2">
                        <a href="/普健.url"> <i class="iconfont icon-all-fill"></i>添加桌面</a>
                        <a href="javascript:/" @mouseenter="(isShowCode = true)" @mouseleave="isShowCode = false"><i
                                class="iconfont icon-mobile1"></i>手机版</a>
                        <div class="code-wrap" v-if="isShowCode">
                            <span class="close" @click.stop="closeFun"></span>
                            <div class="code-cont">
                                <img :src="qrCodeUrl" />
                                <!--              <img src="../../assets/images/ios_android.jpg" />-->
                                <p class="info">扫码安装普健商城APP</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.v2-top {

    width: 100%;



    .icon_hi {
        background: url("../../assets-v2/images/sy_hai.png");
    }

    .height41 {
        background: #F2F2F2;
        line-height: 41px;
        height: 41px;
        border: 1px solid #EDEDED;
    }

    .top-left {
        display: flex;

        .loged {
            position: relative;

            &:hover {
                .user-list {
                    display: block;
                }
            }
        }
    }

    .v2-customer {
      margin-right: 8px;
      display: inline-block;
      max-width: 330px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .v2-link1 {

        a {
            margin: 0 10px;
            text-align: center;
            color: #666666
        }


        i {
            display: inline-block;
            width: 1px;
            background-color: #999999;
            height: 10px;
        }
    }

    .v2-link2 {
        margin-left: 50px;
        position: relative;

        a {
            color: #666666;
            margin: 0 5px;
        }

        i {
            font-size: 16px;
        }

        .code-wrap {
            z-index: 3000;
            width: 246px;
            height: 282px;
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.02);
            background: #fff;
            position: absolute;

            right: 0;
            top: 31px;
            padding: 25px 20px 15px;
            box-sizing: border-box;

            // display: flex;
            // flex-direction: row;
            // align-items: center;
            // justify-content: center;
            .close {
                position: absolute;
                right: 7px;
                top: 3px;
                cursor: pointer;
            }

            .code-cont {
                width: 206px;
                height: 206px;
                padding: 5px;
                border: 1px solid #f0f0f0;

                img {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                }

                .info {
                    margin-top: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(102, 102, 102, 1);
                }
            }
        }
    }

    .tips {
        line-height: 44px;
        height: 44px;
        background: #f5791f4d;
        color: #333333;
        font-weight: 400;

        i {
            font-size: 36px;
            color: #ff7700;
        }

        span {
            vertical-align: top;
        }
    }

    .to-login {
        color: #0054e0;
        line-height: 30px;
        cursor: pointer;

        &.kj {
            margin-left: 10px;

            a {
                color: #0054e0;
            }
        }


    }

    .user-list {
        display: none;
        position: absolute;
        left: 0;
        min-width: 10rem;
        width: 400px;
        top: 100%;
        z-index: 21;
        background-color: #fff;
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 15px 1px #b8b8b8;
        box-shadow: 0 1px 15px 1px #b8b8b8;
        max-height: 365px;
        overflow-y: auto;
        z-index: 3000;

        li {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            color: #666;
            display: block;
            font-size: 13px;
            line-height: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            padding: 11px 25px;
            position: relative;
            cursor: pointer;

            &.nodata {
                margin: 10px auto;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 250px;
                }

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    color: #999999;
                }
            }

            &.cus-search {


                position: sticky;
                top: 0;
                background: #fff;
                z-index: 1;

                input {
                    width: 370px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #dbdbdb;
                    border-radius: 4px;
                }
            }

            &:hover {
                background: #F2F2F2;
                color: #0a0a0a;
            }

            &.selected {
                background: #f2f2f2;

                &::before {
                    display: block;
                    width: 6px;
                    height: 6px;
                    content: '';
                    background: #FB0303;
                    border-radius: 5px;
                    position: absolute;
                    left: 15px;
                    top: 17px;
                }

                color: #333;
                cursor: default;
            }
        }
    }
}
</style>

<script>
import { Config } from "@/config";
import { mapActions, mapGetters } from 'vuex';
import { AccountStorage } from "@services/account";
import account from '@mixin/account'
const accountStorage = new AccountStorage();

export default {
    data() {
        return {
            isShowCode: false,
            loginCompanyName: "",
            loginCompanyId: "",
            qrCodeUrl: `/pjyy-deepexi-aggregation/api/v1/qrcode/getQrCode?url=${Config.env.VUE_APP_WEB_URL}/pjyy-deepexi-aggregation/api/v1/qrcode/rouse?appId=__UNI__1DDC2C0`,
            isLoading: false,
            userList: [],
            isExternal: false,
            userName: '',
            searchName: null,//快速搜索客户
            copyUserList: [],
        };
    },
    mixins: [account],
    mounted() {
        let userInfo = accountStorage.getCacheUserInfo();

        if (userInfo) {
            this.loginCompanyName = userInfo.loginCompanyName;
            this.loginCompanyId = userInfo.loginCompanyId;
            this.isExternal = userInfo.type == 1 ? true : false
            this.userName = userInfo.userName
            this.getUserList();
        }
    },
    computed: {
        ...mapGetters(['iframeLogin']),
    },
    watch: {
        iframeLogin: function (value) { //插件登录，查询用户信息
            if (value) {
                let userInfo = accountStorage.getCacheUserInfo();
                if (userInfo) {
                    this.loginCompanyName = userInfo.loginCompanyName;
                    this.loginCompanyId = userInfo.loginCompanyId;
                    this.isExternal = userInfo.type == 1 ? true : false
                    this.userName = userInfo.userName
                    this.getUserList();
                }
            }
        }
    },

    methods: {
        ...mapActions([
            'resetState'
        ]),

        jump(name) {
            this.$router.push({ name: name });
        },
        quickSearch() {
            this.copyUserList = [];
            if (this.searchName == null || this.searchName == '') {
                this.copyUserList = [...this.userList];
            } else {
                this.userList.forEach(t => {
                    if (t.name.indexOf(this.searchName) >= 0) {
                        this.copyUserList.push(t);
                    }
                })
            }

        },

        goLogin() {
            this.$router.push({ name: "v2-login" });
        },
        goFun(index) {
            if (index === 0) {
                this.$router.push({ path: "/user-center" });
            } else if (index === 1) {
                this.isShowCode = true;
            }
        },
        closeFun() {
            this.isShowCode = !this.isShowCode;
        },
        async getUserList() {
            const userList = accountStorage.getCacheUserList();
            // if (userList) {
            //     this.userList = userList;
            //     this.copyUserList = [...this.userList]
            // } else {
                const URL = `/pjyy-deepexi-user-center/api/v1/company/getRelationAccountList?companyId=${this.loginCompanyId}`;
                const result = await this.$getHttpClient().get(URL);
                if (result.data.code === "200") {
                    this.userList = result.data.data;
                    accountStorage.setCacheUserList(result.data.data);
                    this.copyUserList = [...this.userList]
                }
          //  }
        },
        async onCheckUser(obj) {
            this.isLoading = true;
            const URL = `/pjyy-deepexi-user-center/api/v1/common/switchLoginAcc?companyId=${obj.id}`;
            const result = await this.$getHttpClient().post(URL);
            this.isLoading = false;
            if (result.data.code === "200") {
                accountStorage.setCacheUserInfo(result.data.data);
                accountStorage.removeCacheUserList();
                this.resetState();
                this.$router.push({ name: 'v2-home' });
                this.reload();
            }
        }
    },
    inject: ["reload"]
};
</script>
