import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cc"
  }, [_c('div', {
    staticClass: "s-title v2-g-flex-row"
  }, [_c('span', [_vm._v("共" + _vm._s(_vm.totalCount) + "件商品")]), _c('span', [_vm._v(" 订单金额:"), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("￥" + _vm._s(_vm.cardInfo.allTotalMoney))])]), _c('span', [_vm._v(" 折后价:"), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("￥" + _vm._s(_vm.cardInfo.productSumPrice))])]), _c('span', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.delAll.apply(null, arguments);
      }
    }
  }, [_vm._v("清空")])]), _c('div', {
    staticClass: "list"
  }, [_vm._l(_vm.cardInfo.ocCartLists, function (item) {
    return [_c('div', {
      key: item.productId,
      staticClass: "v2-flex-row-list"
    }, [_c('img', {
      staticStyle: {
        "width": "100px",
        "max-height": "100px"
      },
      attrs: {
        "src": item.picPath
      }
    }), _c('div', {
      staticStyle: {
        "padding-left": "10px"
      }
    }, [_c('div', {
      staticClass: "v2-g-flex-row pro"
    }, [_c('div', {
      staticClass: "info v2-g-flex-column",
      staticStyle: {
        "text-align": "left"
      }
    }, [_c('span', {
      staticClass: "name",
      attrs: {
        "title": item.productName
      }
    }, [_vm._v(_vm._s(item.productName) + "【" + _vm._s(item.specifications) + "】")]), _c('span', {
      staticClass: "factory"
    }, [_vm._v(_vm._s(item.produceFactory))]), _c('span', {
      staticClass: "effect"
    }, [_vm._v("效期：" + _vm._s(item.nearEffectivePeriod))])]), !item.isActivityGood ? _c('div', {
      staticClass: "op v2-g-flex-column"
    }, [_c('i', {
      staticClass: "iconfont icon-ashbin1",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.shopCardDel(item.productId);
        }
      }
    }), _c('span', {
      staticClass: "num"
    }, [_vm._v("× " + _vm._s(item.totalNumber))]), _c('span', {
      staticClass: "price"
    }, [_vm._v("￥" + _vm._s(item.preferentialPrice))])]) : _vm._e()]), item.isActivityGood ? _c('div', {
      staticClass: "SpecialOffer"
    }, [_c('div', {
      staticClass: "SpecialOffer-line"
    }, [_c('span', [_vm._v("￥" + _vm._s(item.superDiscountProductPrice) + " (特价)")]), _c('span', [_vm._v("×" + _vm._s(item.superDiscountProductNum))])]), item.fullReductionProductPrice ? _c('div', {
      staticClass: "SpecialOffer-line"
    }, [_c('span', [_vm._v("￥" + _vm._s(item.fullReductionProductPrice) + " (活动价)")]), _c('span', [_vm._v("×" + _vm._s(item.fullReductionNum))])]) : _vm._e(), item.oldPriceNew ? _c('div', {
      staticClass: "SpecialOffer-line"
    }, [_c('span', [_vm._v("￥" + _vm._s(Number(item.oldPriceNew).toFixed(2)) + " (原价)")]), _c('span', [_vm._v("×" + _vm._s(item.oldNum))])]) : _vm._e()]) : _vm._e()])])];
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };