<template>
    <div class="v2-g-flex-row hisproduct" >
        <div class="search-his">
            <p class="title"><i class="iconfont icon-history"></i>搜索历史 <span class="ashbin link-color" title="删除" @click="delHis"><i class="iconfont icon-ashbin1"></i></span></p>
            <ul class="v2-g-flex-column" v-if="hisList && hisList.length>0">
                <li v-for ="(item,index) in hisList" @click="search(item)" class="link-color" :class="selIndex==index?'sel':''">{{item}}</li>
            </ul>
            <div v-else class="nodata">
                <img src="@/assets-v2/images/nodata.png"/>
                <span>暂无相关商品</span>
            </div>
        </div>
        <div class="search-his hot">
            <p class="title">搜索<img src='@/assets-v2/images/sy_tuijian.png' /></p>
            <ul class="v2-g-flex-column" v-if="hotList.length>0">
                <li v-for="(item,index) in hotList"   @click="search(item.productAlias)" class="link-color" ><i class="num" @click="search(item)" >
                    <img src="@/assets-v2/images/sy_diyi@2x.png" v-if="index==0"/>
                    <img src="@/assets-v2/images/sy_dier@2x.png" v-else-if="index==1"/>
                    <img src="@/assets-v2/images/sy_disan@2x.png" v-else-if="index==2"/>
                    <template v-else>
                        {{index+1}}
                    </template>
                </i>{{ item.productAlias }}<span class='act hb' v-if="item.activity==1">红包</span><span class='act rebate' v-if="item.integral==1">返利</span><span class="search-count">{{ item.pv }}</span></li>
            </ul>
            <div v-else class="nodata">
                <img src="@/assets-v2/images/nodata.png"/>
                <span>暂无相关商品</span>
            </div>
        </div>
    </div>
</template>
<style scoped lang="less" >
.hisproduct {
    line-height: 14px;
    font-size: .875rem;
    height: 100%;
    .search-his {

        width: 50%;
        padding: 0px 10px;
        text-align: left;
        &.hot{
            background-color: #F6F6F6;
            border: 1px solid #EDEDED;
        }
        .title {
            margin: 10px 0;
            color: #333;
            font-size: 1.1rem;
            font-weight: 400;

            i {
                font-size: 1.2rem;
            }
            .ashbin{
                float: right;
                cursor: pointer;

                i{
                    font-size: 1.5rem;
                }
            }
        }
        .nodata{
            margin: 50px auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 250px;
        }
        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            color: #999999;
        }
        }
        ul {

            height: 350px;
            li {
                &.sel{
                    background-color: red;
                }
                font-weight: 400;

                height: 33px;
                line-height: 33px;

                .num{
                    margin: 0 10px 0 3px;
                }
                img{
                    height: 20px;
                }
                .act{
                    font-weight: 400;
                    color: #FFFFFF;
                    font-size: .75rem;
                    width: 30px;
                    padding: 2px 0;

                    display: inline-block;
                    margin-left:2px;
                    border-radius: 7px 0px 7px 7px;
                    line-height: 14px;
                    text-align: center;
                    &.hb{
                        background: linear-gradient(0deg, #FC664C 0%, #FCB466 100%);
                    }
                    &.rebate{
                        background: linear-gradient(0deg, #FE4B4F 0%, #FEA8C1 100%)
                    }
                }
                .search-count{
                    float:right;
                    color: #999999;
                }
            }
        }

    }
}
</style>
<script>
export default {
    data() {
        return {
            hisList:[],
            hotList:[]
        };
    },
    created(){
        this.getHistory();
        this.getHot();
    },
    props:{
        selIndex:{type:Number}
    },
    methods: {
        async getHistory(){
            const url='/pjyy-deepexi-product-center/api/v1/product/PcProduct/searchHistory';
            const result = await this.$getHttpClient({useSpinner:false}).get(url);

           if(result.data.code=='200'){
               this.hisList =result.data.data || [];

           }
        },
        async getHot(){
            const url='/pjyy-deepexi-collect-center/api/v1/search/query';
            const result = await this.$getHttpClient({useSpinner:false}).get(url);

           if(result.data.code=='200'){
               this.hotList =result.data.data;

           }
        },
        async delHis(){
            debugger;
            const url="/pjyy-deepexi-product-center/api/v1/product/PcProduct/deleteHistory";
            const result = await this.$getHttpClient().get(url);
            if(result.data.code="200"){
                this.hisList=[];
            }
        },
        search(item){
            this.$router.push({name:'v2-search',params:{q:item}})
        }
    }
}
</script>
