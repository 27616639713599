<!-- 分类中心 -->
<template>
    <div class="category" @mouseenter.stop="mouseOver()" @mouseleave.stop="(isVisible = visible)">
        <div class="title" v-if="!visible">
            <span>所有商品分类</span>
            <i class="iconfont icon-xiajiantou"></i>
        </div>
        <div class="main-left-cont-wrap" :style="{ 'top': visible ? '0' : '52px' }" v-if="isVisible"
            @mouseenter.stop="leftWrapMouseIn" @mouseleave.stop="leftWrapMouseOut">
            <div class="bg" v-if="isShowSec">
                <div class="sec-cont">
                    <ul class="sec-ul-wrap">
                        <li v-for="(product, index) in childList" :key="index" class="sec-item-li">
                            <p class="sec-item-tit" @click="toProduct(product, 2)">
                                {{ product.catalogName }}
                            </p>
                            <div>
                                <span v-for="(thirdPro, index) in product.childList" :key="index" class="sec-item-span"
                                    @click="toProduct(thirdPro, 3)">
                                  {{ thirdPro.catalogName }}
                                </span>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>
            <ul class="main" style="overflow-y: auto; overflow-x: hidden">
                <li v-for="(item, index) in leftTabList" :key="index" class="main-left-item"
                    @mouseenter.stop="onMouseSecIn(item)" :style="{
                        background: `url('${item.displayPhoto === null ? img : (item.displayPhoto)
                            }') no-repeat 10px center`,
                        'background-size': '20px 20px',
                        'background-color': 'transparent'
                    }">
                    <!-- 1级 -->
                    <div class="item-tit-wrap v2-g-flex-row v2-g-flex-space-between">
                        <span>{{ item.catalogName }}</span>
                        <i class="iconfont icon-play"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
let timer;
export default {
    data() {
        return {
            isHover: false,
            isShowSec: false,
            leftTabList: [],
            childList: [],
            isVisible: true,
            img: require("@/assets-v2/images/left_icon_03.png")
        };
    },
    computed: {
        isHome() {
            return this.$route.name == 'v2-home'
        }
    },
    created() {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        this.leftDataList();
      }, 400)
        this.isVisible = this.visible;
    },
    props: {
        visible: { type: Boolean, default: true }
    },
    methods: {
        mouseOver() {
            this.isVisible = true;
        },
        // 分类
        async leftDataList() {
            const URL = "/pjyy-deepexi-product-center/api/v1/background/PcCatalog/pageList";
            const result = await this.$getHttpClient().post(URL);
            if (result && result.data.code === "200") {
                const dataSource = result.data.data;
                this.leftTabList = dataSource;
                //替换图片
                this.leftTabList[0].displayPhoto = require('@/assets-v2/images/shoplist/1.png')
                this.leftTabList[1].displayPhoto = require('@/assets-v2/images/shoplist/2.png')
                this.leftTabList[2].displayPhoto = require('@/assets-v2/images/shoplist/3.png')
                this.leftTabList[3].displayPhoto = require('@/assets-v2/images/shoplist/4.png')
                this.leftTabList[4].displayPhoto = require('@/assets-v2/images/shoplist/5.png')
                this.leftTabList[5].displayPhoto = require('@/assets-v2/images/shoplist/6.png')
                this.leftTabList[6].displayPhoto = require('@/assets-v2/images/shoplist/7.png')
                this.leftTabList[7].displayPhoto = require('@/assets-v2/images/shoplist/8.png')
                this.leftTabList[8].displayPhoto = require('@/assets-v2/images/shoplist/9.png')

                this.leftTabList.forEach(element => {
                    element.thirdProList = [];
                    if (element.childList && element.childList.length > 0) {
                        element.childList.forEach(ele => {
                            if (ele.childList && ele.childList.length > 0) {
                                ele.childList.forEach(el => {
                                    element.thirdProList.push({
                                        value: el.catalogName,
                                        id: el.id
                                    });
                                });
                            }
                        });
                    } else {
                        element.childList = [{
                            catalogName: element.catalogName,
                            id: element.id
                        }];
                    }
                });
            }
        },
        //分类查询
        toProduct(item, level) {
            let obj = { catalogName: item.catalogName, id: item.id, type: level };
            obj.type = level;
            this.$router.push({ name: 'v2-product', params: { id: '20' }, query: obj });
        },
        onMouseSecIn(item) {
            this.isShowSec = true;
            this.childList = item.childList;
        },
        leftWrapMouseIn() {
            this.isShowSec = true;
        },
        leftWrapMouseOut() {
            this.isShowSec = false;
        }
    }
}
</script>
<style scoped lang="less">
.category {
    width: 100%;
    position: relative;
    color: #333;

    .title {
        background: #3875F6;
        height: 50px;
        font-size: 16px;
        color: #ffffff;
        font-weight: 400;
        line-height: 50px;
        margin: 0 0;
    }

    .main-left-cont-wrap {
        height: 360px;
        background: #FFFFFF;
        box-shadow: 0px 3px 7px 0px rgba(241, 241, 241, 0.85);
        position: absolute;
        width: 255px;

        .bg {
            position: absolute;
            top: 0;
            left: 100%;
            width: calc(1200px - 100%);
            height: 360px;
            position: absolute;
            top: 0;
            left: 100%;
            overflow-x: auto;
            background-color: #fafafa;
            border: 1px solid #e1dede;
            box-shadow: 0 0 5px #e1dede;
            z-index: 999;

            .sec-cont {
                .sec-item-li {
                    margin: 10px 5px;
                    border-bottom: 1px solid #ededed;

                    p {
                        text-align: left;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1rem;
                        margin: 10px 5px;
                        cursor: pointer;
                    }

                    div {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 10px;
                    }

                    span {
                        color: #a9a6a6;
                        margin: 2px 5px;
                        cursor: pointer;

                        &:hover {
                            color: #3875f6
                        }
                    }
                }
            }
        }
    }

    .main {
        text-align: left;

        li {
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #f0f0f0;

            div {
                margin: 0 10px 0 40px;
            }

            &:hover {
                background-color: #fff;
                cursor: pointer;

                span {
                    font-size: 15px;
                    transition: font-size .5s;
                }
            }
        }

        .item-tit-wrap {
            color: #222222;
            font-weight: 400;
            font-size: 0.875rem;

        }
    }
}
</style>
